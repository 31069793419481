import React from "react";
import LayoutHeaderFooter from "../../components/LayoutHeaderFooter/LayoutHeaderFooter";
import FirstComponentSmall from "../../components/FirstComponentSmall/FirstComponentSmall";
import "./Documents.scss";

const Documents = (props) => {
    const {
      content,
    } = props.pageContext;
    const location_href = props.location.pathname;
    return (
      <div className="documents-wrapper">
        <LayoutHeaderFooter
          header_background_color={"#8C17FF"}
          SEO_title={content.SEO.title}
          SEO_description={content.SEO.description}
          SEO_keywords={content.SEO.keywords}
          SEO_location={location_href}
        >
          <FirstComponentSmall
            primary_color={"#5107FF"}
            secondary_color={"#8C17FF"}
            title={content.PageTitle}
            
          />
            <div className="content">
              <p>{content.PageSubtitle}</p>
                <ul>
                {content.Items.map((item, index) => {
              return (
                <li key={index}>
                  <a href={item.ContentPath}>{item.Title}</a>
                </li>
              );
            })}
                </ul>
            </div>
        </LayoutHeaderFooter>
      </div>
    );
  };
  
  export default Documents;
  